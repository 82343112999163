/**
 * 全站路由配置
 *
 * 建议:
 * 1. 代码中路由统一使用 name 属性跳转(不使用 path 属性)
 */
import Vue from 'vue'
import Router from 'vue-router'
import http from '@/utils/httpRequest'
import {
  clearLoginInfo,
  flattenArray
} from '@/utils'
import { permissionMap, menuList } from '@/utils/constants.js'

Vue.use(Router)

// 开发环境不使用懒加载, 因为懒加载页面太多的话会造成 webpack 热更新太慢, 所以只有生产环境使用懒加载
const _import = process.env.NODE_ENV === 'production'
  ? file => () => import('@/views/' + file + '.vue')
  : file => require('@/views/' + file + '.vue').default

// 全局路由(无需嵌套上左右整体布局)
const globalRoutes = [{
  path: '/404',
  component: _import('common/404'),
  name: '404',
  meta: {
    title: '404未找到'
  }
},
{
  path: '/login',
  component: _import('common/login'),
  name: 'login',
  meta: {
    title: '登录'
  }
},
{
  path: '/siteuserlogin',
  component: _import('common/siteuserlogin'),
  name: 'siteuserlogin',
  meta: {
    title: '登录'
  }
},
{
  path: '/siteuser/index',
  component: _import('menus/siteuser/index/list'),
  name: 'siteuserindex',
  meta: {
    title: '首页'
  }
},
{
  path: '/video',
  component: _import('common/videolist'),
  name: 'videolist',
  meta: {
    title: '视频信息'
  }
}
]

// 从 vue.config.js 中生成的信息
// eslint-disable-next-line
const menuFileInfo = MENU_LIST
const dynamicRoutes = [{
  path: 'home',
  component: _import('common/home'),
  name: 'home',
  meta: {
    title: '首页',
    enabled: true
  }
}]
for (const m of menuFileInfo) {
  let path = m
  // 去除 .vue 后缀
  path = path.substr(0, path.length - 4)
  // 替换反斜杠
  path = path.replace(/\\/g, '/').replace(/^\//, '')
  let componentName = 'menus/' + path
  // list 表示对应模块首页，与权限对应，路由中不需要 /list 后缀
  if (path.endsWith('list')) {
    path = path.substr(0, path.length - 5)
  }
  dynamicRoutes.push({
    path: path,
    component: _import(componentName),
    name: path.replace(/\//g, '-'),
    meta: {
      enabled: true
    }
  })
}

// 主入口路由(需嵌套上左右整体布局)
const mainRoutes = {
  path: '/',
  component: _import('main'),
  name: 'main',
  redirect: {
    name: 'home'
  },
  meta: {
    title: '主入口整体布局'
  },
  children: dynamicRoutes,
  beforeEnter (to, from, next) {
    let token = Vue.cookie.get('token')
    if (!token || !/\S/.test(token)) {
      clearLoginInfo()
      next({ name: 'login' })
    } else {
      next()
    }
  }
}

const router = new Router({
  mode: 'history',
  scrollBehavior: () => ({
    y: 0
  }),
  // 是否已经添加动态(菜单)路由
  isAddDynamicMenuRoutes: false,
  routes: globalRoutes.concat(mainRoutes).concat({
    path: '*',
    redirect: {
      name: '404'
    }
  })
})

router.beforeEach((to, from, next) => {
  const stdPath = to.path.replace(/^\//, '')
  const target = dynamicRoutes.find(d => d.path === stdPath)
  if (getCurrentRouteType(to) === 'global') {
    // 全局路由
    next()
  } else if (!router.options.isAddDynamicMenuRoutes) {
    // 注册动态(菜单)路由
    http({
      url: http.adornUrl('/user/role/userPermissions'),
      method: 'post',
      params: http.adornParams()
    }).then((data) => {
      if (data && data.code === 200) {
        const permissions = (data.datas || []).map(d => d.value)
        processDynamicMenus(permissions)
        router.options.isAddDynamicMenuRoutes = true
        sessionStorage.setItem('permissions', JSON.stringify(permissions))
        next({ ...to, replace: true })
      } else {
        resetPermission()
        sessionStorage.setItem('permissions', '[]')
        next({ name: 'login' })
      }
    })
  } else if (target) {
    // 首页逻辑
    if (from.name === 'login') {
      if (target.name === 'home' && !target.meta.enabled) {
        // 无首页权限
        const enabledRoutes = dynamicRoutes.filter(m => m.meta.enabled)
        const memus = flattenArray(menuList).filter(m => {
          return m.route && enabledRoutes.find(r => r.name === m.route)
        })
        if (memus.length) {
          next({ name: memus[0].route })
        } else {
          next({ name: '404' })
        }
      } else {
        // 拥有首页权限
        next()
      }
    } else {
      // 正常路由
      target.meta.enabled ? next() : next({ name: '404' })
    }
  } else {
    next({ name: '404' })
  }
})

/**
 * 判断当前路由类型, global: 全局路由, main: 主入口路由
 * @param {*} route 当前路由
 */
function getCurrentRouteType (route) {
  const list = flattenArray(globalRoutes)
  for (let i = 0; i < list.length; i++) {
    if (route.path === list[i].path) {
      return 'global'
    }
  }
  return 'main'
}

// 重置访问权限
function resetPermission () {
  for (const r of mainRoutes.children) {
    r.meta.enabled = false
  }
}

// 权限开关，方便调试
const permissionSwitch = true
// 白名单
const permissionWhiteList = []

/**
 * 根据权限列表处理动态菜单，判断是否启用
 * @param {*} permissions 权限列表
 */
function processDynamicMenus (permissions = []) {
  resetPermission()
  // 权限列表转换成对象，优化速度
  const po = {}
  for (const p of permissions) {
    const values = permissionMap[p]
    if (Array.isArray(values)) {
      for (const v of values) {
        po[v] = true
      }
    } else {
      po[values] = true
    }
  }
  // 线上发布无权限版本处理
  for (const r of mainRoutes.children) {
    if ((process.env.NODE_ENV === 'development' && !permissionSwitch) || permissionWhiteList.includes(r.name) || po[r.name]) {
      r.meta = {
        enabled: true
      }
    }
  }
  // for (const r of mainRoutes.children) {
  //   if ((!permissionSwitch) || permissionWhiteList.includes(r.name) || po[r.name]) {
  //     r.meta = {
  //       enabled: true
  //     }
  //   }
  // }
  sessionStorage.setItem('dynamicRoutes', JSON.stringify(dynamicRoutes || '[]'))
}

export default router
