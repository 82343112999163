import Vue from 'vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(Element, {
  size: 'medium'
})

Vue.prototype.$opts = {
  alert (info) {
    Vue.prototype.$alert(info, '提示', {
      confirmButtonText: '确定',
      type: 'warning'
    })
  },
  success () {
    Vue.prototype.$message({
      message: '操作成功',
      type: 'success',
      duration: 1500
    })
  }
}
