import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './themes'
import VueCookie from 'vue-cookie' // api: https://github.com/alfhen/vue-cookie
import '@/assets/scss/index.scss'
import httpRequest from '@/utils/httpRequest'
import { isAuthed } from '@/utils'

Vue.use(VueCookie)
Vue.config.productionTip = false

// 挂载全局
// ajax 请求方法
Vue.prototype.$http = httpRequest
// 权限方法
Vue.prototype.isAuthed = isAuthed

window.SITE_CONFIG = {}

// 保存整站 vuex 本地储存初始状态
window.SITE_CONFIG['storeState'] = JSON.parse(JSON.stringify(store.state))

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
