export default {
  namespaced: true,
  state: {
    // 侧边栏是否展开
    expanded: false
  },
  mutations: {
    toggleExpanded (state, expanded) {
      if (expanded !== undefined) {
        state.expanded = expanded
      } else {
        state.expanded = !state.expanded
      }
    }
  }
}
