<template>
  <transition name="fade">
    <router-view></router-view>
  </transition>
</template>

<script>
export default {
  name: 'App'
}
</script>
<style lang='scss'>
body .el-table th.gutter {
  display: table-cell !important;
}
</style>
