import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import store from '../store'
import qs from 'qs'
import {
  clearLoginInfo
} from '@/utils'

const http = axios.create({
  timeout: 1000 * 30,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  // 请求头带上token
  if (Vue.cookie.get('token')) {
    config.headers['token'] = Vue.cookie.get('token')
  }

  console.log(store.state.user.token)
  if (store.state.user.token) {
    config.headers['token'] = store.state.user.token
  }

  return config
}, error => {
  return Promise.reject(error)
})

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  // 403, token失效
  if (response.data && response.data.code === 403) {
    clearLoginInfo()
    router.push({
      name: 'login'
    })
    return
  }
  if (response.headers['content-type'].includes('application/json') && response.data.code !== undefined && response.data.code !== 200) {
    Vue.prototype.$message.error(response.data.msg)
  }
  return response.data
}, error => {
  Vue.prototype.$message.error(error.response.data.msg)
  return Promise.reject(error)
})

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornUrl = (actionName) => {
  // 生产环境配置接口地址，便于分开部署
  return (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_URL : '') + '/bengche' + actionName
}

/**
 * get 请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = true) => {
  const defaults = {
    't': new Date().getTime()
  }
  return openDefultParams ? Object.assign({}, defaults, params) : params
}

/**
 * post 请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefultdata = true, contentType = 'json') => {
  const defaults = {
    't': new Date().getTime()
  }
  data = openDefultdata ? Object.assign({}, defaults, data) : data
  return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data)
}

export default http
