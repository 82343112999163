export const menuList = [
  { id: 1, image: '/img/home.png', name: '首页', route: 'home' },
  {
    id: 2,
    image: '/img/tuner.png',
    name: '生产调运',
    expanded: false,
    children: [
      { parentId: 2, id: 21, name: '调运看板', route: 'dispatch-board' },
      { parentId: 2, id: 23, name: '调运记录', route: 'dispatch-record' },
      { parentId: 2, id: 28, name: '调运任务', route: 'dispatch-task' },
      { parentId: 2, id: 25, name: '实时方量', route: 'dispatch-quantity' },
      // { parentId: 2, id: 25, name: '调运下发', route: 'dispatch-record_send' },
      { parentId: 2, id: 24, name: '调运方量', route: 'dispatch-amount' },
      { parentId: 2, id: 22, name: '工地信息', route: 'dispatch-site' },
      { parentId: 2, id: 26, name: '工地巡查', route: 'dispatch-patrol' },
      { parentId: 2, id: 27, name: '多次巡查', route: 'dispatch-multiplePatrol' }
    ]
  },
  {
    id: 3,
    image: '/img/topology.png',
    name: '生产管理',
    expanded: false,
    children: [
      { parentId: 3, id: 31, name: '生产监控', route: 'production-monitor' },
      { parentId: 3, id: 32, name: '历史视频', route: 'production-history' },
      { parentId: 3, id: 33, name: '车辆轨迹', route: 'production-track' }
    ]
  },
  {
    id: 4,
    image: '/img/car.png',
    name: '车辆管理',
    expanded: false,
    children: [
      { parentId: 4, id: 41, name: '车辆信息', route: 'car-car' },
      { parentId: 4, id: 42, name: '合作公司', route: 'car-partner' },
      { parentId: 4, id: 43, name: '车辆维修', route: 'car-repair' },
      { parentId: 4, id: 44, name: '车辆检测', route: 'car-detection' },
      { parentId: 4, id: 45, name: '车辆违章', route: 'car-violation' },
      { parentId: 4, id: 46, name: '安全事故', route: 'car-safe' },
      { parentId: 4, id: 47, name: '车辆油料', route: 'car-oil' }
    ]
  },
  {
    id: 5,
    image: '/img/person.png',
    name: '人员管理',
    expanded: false,
    children: [
      { parentId: 5, id: 51, name: '人员', route: 'person-person' },
      { parentId: 5, id: 52, name: '角色', route: 'person-role' },
      { parentId: 5, id: 53, name: '培训', route: 'person-train' },
      { parentId: 5, id: 54, name: '组织机构', route: 'person-organization' },
      { parentId: 5, id: 55, name: '黑名单', route: 'person-black' },
      { parentId: 5, id: 56, name: '工地用户', route: 'person-siteuser' }
    ]
  },
  {
    id: 6,
    image: '/img/report.png',
    name: '报表查询',
    expanded: false,
    children: [
      { parentId: 6, id: 61, name: '方量报表', route: 'report-amount' },
      { parentId: 6, id: 66, name: '拌站方量', route: 'report-stirAmount' },
      { parentId: 6, id: 62, name: '车辆方量', route: 'report-carAmount' },
      { parentId: 6, id: 63, name: '基地方量', route: 'report-baseAmount' },
      { parentId: 6, id: 64, name: '方量趋势', route: 'report-trendAmount' },
      { parentId: 6, id: 65, name: '方量自运率', route: 'report-rateAmount' },
      { parentId: 6, id: 67, name: '巡查统计', route: 'report-patrolStatistics' }
    ]
  },
  {
    id: 7,
    image: '/img/settings.png',
    name: '系统设置',
    expanded: false,
    children: [
      { parentId: 7, id: 71, name: '人员设置', route: 'system-person' },
      { parentId: 7, id: 72, name: '车辆设置', route: 'system-car' },
      { parentId: 7, id: 73, name: '告警设置', route: 'system-alert' },
      { parentId: 7, id: 74, name: '维修设置', route: 'system-repair' }
      // { parentId: 7, id: 74, name: '基础设置', route: 'system-basics' }
      // { parentId: 7, id: 75, name: '围栏设置', route: 'system-rail' }
    ]
  },
  {
    id: 8,
    image: '/img/site.png',
    name: '工地数据',
    expanded: false,
    children: [
      { parentId: 8, id: 81, name: '主页', route: 'siteuser-index' },
      { parentId: 8, id: 82, name: '实时方量', route: 'siteuser-real' },
      { parentId: 8, id: 83, name: '方量报表', route: 'siteuser-static' }
      // { parentId: 7, id: 74, name: '基础设置', route: 'system-basics' }
      // { parentId: 7, id: 75, name: '围栏设置', route: 'system-rail' }
    ]
  }
]

export const operationTypes = {
  review: '审核',
  update: '修改',
  insert: '创建',
  delete: '删除'
}

export const userTypes = [
  { id: 1, name: '内部员工' },
  { id: 2, name: '派遣员工' },
  { id: 3, name: '合作方员工' }
]

export const jobStatusMap = {
  1: '待调度',
  2: '正常施工',
  3: '工地故障',
  4: '待排故障',
  5: '离线',
  6: '维修'
}

export const jobStatusImageMap = {
  1: '/img/state-dark-1.png',
  2: '/img/state-dark-2.png',
  3: '/img/state-dark-3.png',
  4: '/img/state-dark-4.png',
  5: '/img/state-dark-5.png',
  6: '/img/state-dark-6.png'
}

export const jobStatusImageLightMap = {
  1: '/img/state-light-1.png',
  2: '/img/state-light-2.png',
  3: '/img/state-light-3.png',
  4: '/img/state-light-4.png',
  5: '/img/state-light-5.png',
  6: '/img/state-light-6.png'
}

export const permissionMap = {
  index_view: 'home',
  dispatch_board_view: 'dispatch-board',
  dispatch_task_view: 'dispatch-task',
  dispatch_record_view: ['dispatch-record', 'dispatch-record-info'],
  dispatch_record_add: 'dispatch-record-update',
  dispatch_quantity: 'dispatch-quantity',
  dispatch_record_update: 'dispatch-record-update',
  dispatch_decision_view: 'dispatch-record_send',
  site_record_view: ['dispatch-site', 'dispatch-site-info'],
  site_record_add: 'dispatch-site-update',
  site_record_update: 'dispatch-site-update',
  dispatch_quantity_view: 'dispatch-amount',
  dispatch_patrol_view: ['dispatch-patrol', 'dispatch-patrol-info'],
  dispatch_patrol_multview: ['dispatch-multiplePatrol'],
  report_patrol_statistics: 'report-patrolStatistics',
  management_video_view: 'production-monitor',
  management_beng_view: 'production-approve',
  history_video_view: 'production-history',
  history_trail_view: 'production-track',
  car_info_view: ['car-car', 'car-car-info'],
  car_info_add: 'car-car-update',
  car_info_update: 'car-car-update',
  car_info_approve: 'car-car-verify',
  car_company_view: 'car-partner',
  car_company_add: 'car-partner-update',
  car_company_update: 'car-partner-update',
  company_dispatcher_update: 'car-partner-assign',
  company_dispatcher_approve: 'car-partner-verify',
  car_check_view: ['car-detection', 'car-detection-info'],
  car_check_add: ['car-detection-update', 'car-safe-update'],
  car_check_update: ['car-detection-update', 'car-safe-update'],
  car_break_view: 'car-violation',
  car_safe_view: 'car-safe',
  car_repair_view: 'car-repair',
  car_repair_add: 'car-repair-update',
  car_repair_update: 'car-repair-update',
  car_oil_view: 'car-oil',
  car_oil_add: 'car-oil-update',
  car_oil_update: 'car-oil-update',
  car_oil_approve: 'car-oil-verify',
  sys_role_view: 'person-role',
  user_role_save: 'person-role-update',
  user_role_update: 'person-role-update',
  sys_user_view: 'person-person',
  sys_user_add: 'person-person-update',
  sys_user_update: 'person-person-update',
  sys_train_view: 'person-train',
  sys_train_add: 'person-train-update',
  sys_train_update: 'person-train-update',
  sys_userblack_view: 'person-black',
  sys_userblack_add: 'person-black-update',
  sys_userblack_update: 'person-black-update',
  sys_siteuser_view: 'person-siteuser',
  sys_siteuser_add: 'person-siteuser-update',
  sys_siteuser_update: 'person-siteuser-update',
  sys_org_view: 'person-organization',
  quantity_record_view: 'report-amount',
  quantity_station_view: 'report-stirAmount',
  quantity_car_view: 'report-carAmount',
  quantity_site_view: 'report-baseAmount',
  quantity_trend_view: 'report-trendAmount',
  quantity_self_view: 'report-rateAmount',
  setting_user_update: 'system-person',
  setting_car_update: 'system-car',
  setting_alarm_update: 'system-alert',
  setting_base_update: 'system-basics',
  setting_fence_update: 'system-rail',
  setting_repair_update: 'system-repair',
  site_user_index: 'siteuser-index',
  site_user_real_quantity: 'siteuser-real',
  site_user_quantity_static: 'siteuser-static'
}
