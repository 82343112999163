import Vue from 'vue'
import router from '@/router'
import store from '@/store'

/**
 * 获取 uuid
 */
export function getUUID () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
  })
}

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuthed (key) {
  return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
}

/**
 * 清除登录信息
 */
export function clearLoginInfo () {
  Vue.cookie.delete('token')
  store.commit('resetStore')
  router.options.isAddDynamicMenuRoutes = false
}

// 展开多维数组
export function flattenArray (array, childProp = 'children', removeEmptyChildren = false) {
  const result = []
  array.forEach((item) => {
    if (item[childProp]) {
      if (removeEmptyChildren) {
        if (item[childProp].length) {
          result.push.apply(result, flattenArray(item[childProp], childProp))
        } else {
          result.push(item)
        }
      } else {
        result.push.apply(result, flattenArray(item[childProp], childProp))
      }
    } else {
      result.push(item)
    }
  })
  return result
}

export function download (exportUrl, filterForm, fileName, errMsg) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http({
      url: Vue.prototype.$http.adornUrl(exportUrl),
      method: 'post',
      data: Vue.prototype.$http.adornData(filterForm),
      responseType: 'arraybuffer'
    }).then((data) => {
      let blob = new Blob([data], {
        type: 'application/octet-stream'
      })
      let filename = (fileName || new Date().getTime()) + '.xlsx'
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(blob, filename)
        resolve()
      } else {
        var blobURL = window.URL.createObjectURL(blob)
        var tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', filename)
        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank')
        }
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(blobURL)
        resolve()
      }
    }).catch((error) => {
      console.log(error)
      Vue.prototype.$message.error(errMsg || '网络请求出错')
      reject(error)
    })
  })
}
